import { Accordion, Card, Button, Row, Col, Container, Jumbotron, ListGroup, Modal, Spinner } from 'react-bootstrap'
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

const CV = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [showTreehouse, setShowTreehouse] = useState(false);
  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(true);

  const handleClose = () => setShow(false);
  const handleCloseTreehouse = () => setShowTreehouse(false);

  const handleShowTreehouse = () => {
    setShowTreehouse(true);
    setLoading(true);
  }
  const handleShowCert = () => {
    setShow(true);
    setLoading(false);
  }

  const handleImageLoaded = () => {
    setLoading(false);
  }

  return (
    <div className="cardList">
      <Container>
        <Row>
          <Col md={12} lg={12}>
            <Jumbotron>
              <Accordion defaultActiveKey="0">
                <h1>
                  Curriculum Vitae
                </h1>
                <p><a href="/docs/Kevin-Madden-Resume.pdf" class="resume-dl" target="_blank" rel="noopener noreferrer" style={{ fontWeight: "bold" }}>Download</a> a current copy of my resume.</p>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      <strong>GoodFood</strong> - Front End Developer (November 2021-Present)
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ListGroup.Item>Setup Google Tag Manager for integration with Google Universal Analytics, Google Analytics 4, and other 3rd party Analytics applications (Emarsys).  This includes writing GTM-side scripts to extract information from the site that isn’t being pushed to the data layer.</ListGroup.Item>
                      <ListGroup.Item>Updating a recent revision of their website built with (NextJS with Context) to push relevant information to the GTM Data Layer, so this data is available for analytics purposes.   This includes creating custom events, triggers and data in the front-end code.  I also need to ensure proper formatting of the data to ensure its acceptance in third party applications.</ListGroup.Item>
                      <ListGroup.Item>Updating their app (React Native with Redux) building out components and functionality and bugfixing as needed. </ListGroup.Item>
                      <ListGroup.Item>Updated Analytics documentation in Confluence.</ListGroup.Item>

                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      <strong>Bounteous</strong> - Front End Developer (January 2021-November 2021)
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <ListGroup.Item>Create custom pages on Wawa.com for various campaigns, as well as general site layout updates and improvements.  Kentico based CMS, and updates were made using HTML, JavaScript, jQuery, and Sass/CSS.</ListGroup.Item>
                      <ListGroup.Item>Used React with React-Tables to create an Allergens page for Wawa – users would select from a set of allergens, which would query an API and return a list of foods containing selected allergens.  Table contained filtering and dropdown capabilities (for nested product categories).</ListGroup.Item>
                      <ListGroup.Item>Create responsive emails for various campaigns and tested them using Litmus.</ListGroup.Item>
                      <ListGroup.Item>Carve assets out of .psd files for various updates to Wawa’s in-store ordering terminals</ListGroup.Item>

                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                      <strong>Bell Media</strong> - Front End Developer / Site Production Specialist (November 2019- January 2021)
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <ListGroup>
                        <ListGroup.Item>Updating the Bell.ca website using HTML5, CSS3, JavasScript, and jQuery</ListGroup.Item>
                        <ListGroup.Item>Working with clients and stakeholders to ensure accurate, timely delivery of website content</ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                      <strong>Q4 Inc</strong> - Front End Developer / Web Support Analyst (June 2014 - February 2019)
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <ListGroup>
                        <ListGroup.Item>Primary role at Q4 was to maintain and update company Investor Relations websites and apps. Worked with companies including Best Buy, Wal-Mart, LinkedIn, Nike, Shopify, Apple and other large Fortune 500 IR teams</ListGroup.Item>
                        <ListGroup.Item>Updated and built websites using HTML5, CSS3, jQuery, and JavaScript</ListGroup.Item>
                        <ListGroup.Item>Built out custom functionality as required</ListGroup.Item>
                        <ListGroup.Item>Built site pages from Photoshop mock-ups</ListGroup.Item>
                        <ListGroup.Item>Drafted responsive emails in HTML/CSS and distribute to client email subscribers</ListGroup.Item>
                        <ListGroup.Item>Optimized SEO using meta, alt, and title tags, site-speed optimization, as well as using sitemaps via Webmaster Tools, Google Analytics & Search Console, 301 redirects, and more</ListGroup.Item>
                        <ListGroup.Item>Updated sites to ensure Accessibility/WCAG compliance (ARIA labels, title tags, alt tags, contrast toggles, etc…)</ListGroup.Item>
                        <ListGroup.Item>Worked with clients and stakeholders to ensure accurate, timely delivery of website content</ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="4">
                      <strong>SigmaXL Inc.</strong> - Webmaster / Office Manager / Software QA (November 2010 - March 2014)
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      <ListGroup>
                        <ListGroup.Item>Updated the website using HTML, CSS and JavaScript</ListGroup.Item>
                        <ListGroup.Item>Updated software help documentation</ListGroup.Item>
                        <ListGroup.Item>Optimized the website's organic and paid SEO using Google AdWords, Bing Ads, Google Analytics and Webmaster Tools</ListGroup.Item>
                        <ListGroup.Item>Managed Social Media presence (Twitter, Facebook, LinkedIn)</ListGroup.Item>
                        <ListGroup.Item>Managed Invoicing, Sales Tracking and Processing</ListGroup.Item>
                        <ListGroup.Item>Handled accounts payable and payroll</ListGroup.Item>
                        <ListGroup.Item>Tested software to ensure compliance with workbook</ListGroup.Item>
                        <ListGroup.Item>Created, narrated, edited, and compressed website help videos with Camtasia </ListGroup.Item>
                        <ListGroup.Item>Provided technical support to clients over phone, email and live chat</ListGroup.Item>
                        <ListGroup.Item>Working with clients and stakeholders to ensure accurate, timely delivery of website content</ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="5">
                      <strong>Education</strong>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="5">
                    <Card.Body>
                      <ListGroup>
                        <ListGroup.Item>Simon Fraser University: Bachelor of Arts, Major: Criminology. 2007.</ListGroup.Item>
                        <ListGroup.Item>Team Treehouse: <a rel="noopener noreferrer" href="https://join.teamtreehouse.com/full-stack-javascript-techdegree/?_ga=2.160914429.1792897713.1575135632-963753930.1575135632" target="_blank">FullStack JavaScript</a>. <a className="link" onClick={handleShowTreehouse}>Certificate of Completion</a>. 2019.</ListGroup.Item>
                        <ListGroup.Item>Google Tag Manager Fundamentals. <a className="link" onClick={handleShowCert}>Certificate of Completion</a>. 2021.</ListGroup.Item>

                      </ListGroup>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Jumbotron>
            <button onClick={() => props.history.goBack()} className="back-btn">Go Back</button>
          </Col>
        </Row>
        <Modal show={showTreehouse} onHide={handleCloseTreehouse}>
          <Modal.Header closeButton>
            <Modal.Title>Certificate of Completion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: loading ? "block" : "none" }}>
              <Spinner animation="border" />
            </div>
            <img
              src="https://api.accredible.com/v1/frontend/credential_website_embed_image/certificate/13234958"
              onLoad={() => handleImageLoaded()}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseTreehouse}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Certificate of Completion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: loading ? "block" : "none" }}>
              <Spinner animation="border" />
            </div>
            <Document
              file="/docs/Course_Certificate.pdf"
              onLoad={() => handleImageLoaded()}
            >
              <Page pageNumber={1} wrap={false} />
            </Document>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>

  );
}

export default CV;