import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {
    BrowserRouter,
    Route,
  } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import {createStore, applyMiddleware, compose} from 'redux';
import herokuReducer from './reducers/herokuState';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
// import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

// const TRACKING_ID = "G-JXTHMBYZ8E"; // YOUR_OWN_TRACKING_ID

const tagManagerArgs = {
    gtmId: 'GTM-TLCMWB8'
  }

// ReactGA.initialize(TRACKING_ID);
TagManager.initialize(tagManagerArgs)


const middleware = [thunk];

let initialState = {
  loadStatus: "",
  loadFailed: "false",
  newLink: "",
  setShow: false,
  dataLayer: []
}

const store = createStore(
  herokuReducer,
  initialState,
  compose(
  applyMiddleware(...middleware)
  )
);


ReactDOM.render(<BrowserRouter><Provider store={store}><Route path="/" render={(props) => <App {...props} />}  /></Provider></BrowserRouter>, document.getElementById('root'));

serviceWorker.unregister();
